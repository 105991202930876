import { Breadcrumbs } from "@material-ui/core";
import { Action, Dispatch } from "redux";
import { IconTypes } from "../../common/components";
import { BreadcrumInfo } from "../../common/components/helpers/breadcrum-navigator";
import { HeaderAction } from "../../services/ux";

import { AppState } from "../combineReducers";
import { NavBarActionTypes } from "./nav-bar-actions.types";

export interface ConfigureNavBar {
    (visible: boolean, actions: HeaderAction[], footerVisible: boolean): Action
}
export interface SetBreadcrumbs {
    (items?: BreadcrumInfo[]): any;
}

export interface ConfigureNavBarAction extends Action {
    type: NavBarActionTypes;
    actions: HeaderAction[];
    visible: boolean;
    footerVisible: boolean;
}

export interface HideFooterAction extends Action {
    type: NavBarActionTypes;
    footerVisible: boolean;
}

export interface SetNavBarPageTitleAction extends Action {
    type: NavBarActionTypes;
}


export interface BreadcrumbAction extends Action {
    type: NavBarActionTypes;
    items: BreadcrumInfo[]
}



export const configureNavBar: ConfigureNavBar = (visible: boolean, actions: HeaderAction[],   footerVisible: boolean) => {


    let action: ConfigureNavBarAction = {
        type: NavBarActionTypes.CONFIGURE_NAV_BAR,
        visible: visible,
        actions: actions,
        footerVisible: footerVisible
    };

    return action;


}

export const showFooter  = (value: boolean) => {
    return (dispatch: Dispatch<HideFooterAction>, getState: () => AppState) => {
        let action : HideFooterAction = {
            type: NavBarActionTypes.HIDE_FOOTER,
            footerVisible: value
        }
        dispatch(action);
    }
}

export const setBreadcrumbs : SetBreadcrumbs = (items?: BreadcrumInfo[]) => {
    return (dispatch: Dispatch<BreadcrumbAction>, getState: () => AppState) => {

        const pathToTitle = (segment: string) => {
            switch (segment.toLocaleLowerCase()) {
                case "/": return "HOME"
                case "home": return "HOME"
                case "users": return "USERS"
                case "apps": return "APPS"
                case "app": return "APP"
                case "user": return "USER"
                case "config" : return "CONFIG"
                default: return ""
            }
        }

      



        let location = window.location.pathname;
        let crumbs = new Array<BreadcrumInfo>();

            let parts = location.split("/")

            let path = "";

            for (let i = 0; i < parts.length; i++) {
                const segment = parts[i];

                if (segment) {
                    path = `${path}/${segment}`;
                    let title = pathToTitle(segment);
                    if (title) {
                        crumbs.push({
                            path: path,
                            title: title
                        })
                    }
                }
            }

        

        if (items)
        crumbs.push(...items)

        let action: BreadcrumbAction = {
            type: NavBarActionTypes.SET_BREAD_CRUMBS,
            items: crumbs
        }

        dispatch(action);

    }
}


export type NavBarActions = ConfigureNavBarAction | SetNavBarPageTitleAction | BreadcrumbAction | HideFooterAction