export enum MessageActionTypes {
    SHOW_MESSAGE = 'SHOW_MESSAGE',
    HIDE_MESSAGE = 'HIDE_MESSAGE'
}


export enum MessageType {
    Info = 'info',
    Warning = 'warning',
    Error = 'error'
}

export enum MessagePositionVertical {
    top = 'top',
    bottom = 'bottom'
}

export enum MessagePostionHorizontal {
    left = 'left',
    right = 'right',
    center = 'center'
}