import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { Roles } from 'steltix-framework-authentication-sdk'
import { CurrentUserState, getCurrentUser, MessageType, showMessageBox, ShowMessageBox } from "../../redux";
import { AppState } from "../../redux/combineReducers";
import { LanguageService, StxAuthenticationService } from "../../services";
import history from '../../history';


export interface AuthenticatedRouteProps extends RouteProps {
    restrictedToRoles?: Roles[];
}

export default function AuthenticatedRoute(props: AuthenticatedRouteProps) {
    const dispatch =useDispatch();

    const t = LanguageService.getTranslatedText;
    const currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;
    const [_valid, setValid] = useState<boolean>(false);


    useEffect(() => {
        let isAuthenticated = StxAuthenticationService.isAuthenticated();
        let roleValid = true;
        let restrictedToRoles = props.restrictedToRoles ? props.restrictedToRoles : [];
        if (isAuthenticated) {
            let user = currentUser.authUser;
            if (!user) {
                dispatch(getCurrentUser);
            }
            if (user && restrictedToRoles.length > 0) {
                roleValid = false;
                let userRoles = user.roles;
                if (userRoles) {
                    for (let i = 0; i < restrictedToRoles.length; i++) {
                        const role = restrictedToRoles[i];
                        if (userRoles.includes(role)) {
                            roleValid = true;
                            break;
                        }
                    }
                    if (!roleValid) {
                        showMessageBox(t('INVALID_RIGHTS'), MessageType.Error, 5000)
                        history.push("/")
                    } else {
                        setValid(roleValid);
                    }
                }
            }
        } else {
            history.push("/login")
        }

    }, [currentUser]);

    return (
        _valid ? <Route {...props} /> : <div>Loading...</div>
    )

}