import { DateUtils } from "../../common";
import { AppState } from "../../redux/combineReducers";


export class DataStore {

    public static setData(key: string, data: any) {
        let json = JSON.stringify(data);
        localStorage.setItem(key, json);
    }

    public static getData(key: string): any {
        let json = localStorage.getItem(key);
        if (json && json !== "{}")
            return JSON.parse(json, DateUtils.ReviveDateTime);
        return null;
    }

    public static removeData(key: string) {
        localStorage.removeItem(key);
    }

    public static keys() {
        return Object.keys(localStorage);
    }

    public static clearAll() {
        localStorage.clear();
    }
}
    

export class DataStoreKeys {
    public static REDUX = 'redux';
    public static AUTH_TYPE = 'auth-type';
    public static ID_TOKEN = 'idToken';
    public static AUTHENTICATION_RESULT = 'authentication-result';
    public static CURRENT_USER = 'currentUser';
    public static CRM_COURSE_PREFIX = 'crm-course';
    public static CRM_GROUP_PREFIX = 'crm-group';
    public static CRM_LESSON_PREFIX = 'crm-lesson';
    public static CRM_USER_PREFIX = 'crm-user';
    public static REPO_PACKAGE_PREFIX = 'rep-package';
    public static USER_PREFIX = 'user';
    public static RUN_PREFIX = 'run';
    public static RUN_SESSION_PREFIX = 'run_session';
    public static ENV_VARIABLES = "environment_variables";
}