import { Action, Dispatch } from "redux";
import { SettingsService } from "../../services";
import { EnvironmentVarService } from "../../services/env-var.service";
import { EnvVariable } from "../../types";
import { AppState } from "../combineReducers";
import { SettingsActionTypes } from "./settings.actions.types";


export interface SettingsEnvVarAction extends Action {
    type: SettingsActionTypes;
    vars: EnvVariable[];
}

export interface SettingsVersionAction extends Action {
    type: SettingsActionTypes;
    version: string;
}

export async function getEnvVariables(dispatch: Dispatch<SettingsEnvVarAction>, getState: () => AppState) {
    let envServer = new EnvironmentVarService();
    let data = await envServer.load();


    let action: SettingsEnvVarAction = {
        type: SettingsActionTypes.GET_ENV_VARS,
        vars: data
    }

    dispatch(action);
}

export async function getVersion(dispatch: Dispatch<SettingsVersionAction>, getState: () => AppState) {

    let version = getState().settings.version;
    let ss = new SettingsService();
    if (ss.production && version === 'DEBUG') {
        const result = await fetch('/settings?json=true', { method: 'GET' });
        let data = await result.json();
        if (data && data.version) {
            let ver = data.version;

            let action: SettingsVersionAction = {
                type: SettingsActionTypes.GET_VERSION,
                version: ver
            }
            dispatch(action);
        }
    }

    
}

export type SettingsActions = SettingsEnvVarAction | SettingsVersionAction