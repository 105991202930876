import { Reducer } from "redux";
import { LayoutActionTypes } from ".";
import { mainStyle } from "../../styles";
import { LayoutActions, SetPageSizeAction } from "./layout-actions";


export interface AppsPageState {

    gridMaxHeight: number;
}

export interface UsersPageState {
    gridMaxHeight: number;
}


export interface LayoutState {
    appsPage: AppsPageState,
     usersPage: UsersPageState

}


export const initialLayoutState: LayoutState = {
    appsPage: {
        gridMaxHeight: Math.floor(window.innerHeight - 300)
    },
    usersPage: {
        gridMaxHeight: Math.floor(window.innerHeight - 300)
    }

}

export const layoutReducer: Reducer<LayoutState, LayoutActions> = (
    state = initialLayoutState,
    action
) => {

   
    switch (action.type) {
        
        case LayoutActionTypes.APPS_RESIZE: {
           
            let act = action as SetPageSizeAction
            let appPage : AppsPageState = {
                gridMaxHeight: act.gridMaxHeight
            }
            return {
                ...state,
                appsPage: appPage
            }
        }

        case LayoutActionTypes.USERS_RESIZE: {
           
            let act = action as SetPageSizeAction
            let usersPage : UsersPageState = {
                gridMaxHeight: act.gridMaxHeight
            }
            return {
                ...state,
                users: usersPage
            }
        }

        default:
            return state;
    }

}