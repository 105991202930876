import * as React from 'react';
import { Card, Grid, Button, IconButton, Tooltip, Hidden } from "@material-ui/core";
import IconLabel, { IconLabelOptions } from "./icon-label";
import Icon, { IconTypes } from './icon';
import { LanguageService } from '../../../services';
import { HeaderAction, NavActionService } from '../../../services/ux';
import { Translate } from "react-translated";
import { mainStyle } from '../../../styles';


export interface IHeaderMenuProps {
  actions: HeaderAction[],
  layout?: string
}

export interface IHeaderMenuState {
  actions: HeaderAction[]
}

export default class HeaderMenu extends React.Component<IHeaderMenuProps, IHeaderMenuState> {

  constructor(props: IHeaderMenuProps) {
    super(props);

    this.state = {
      actions: this.props.actions ? this.props.actions : [{ label: 'Default Action', icon: IconTypes.Admin }] as HeaderAction[]
    }
  }

  doNaviation = (action: HeaderAction) => {
    NavActionService.handleRoute(action);
  }


  static getDerivedStateFromProps(props: IHeaderMenuProps, currentState: IHeaderMenuState): IHeaderMenuState {

    return {
      actions: props.actions ? props.actions : [{ label: 'Default Action', icon: IconTypes.Admin }] as HeaderAction[]
    }
  }


  public render() {
    const t = LanguageService.getTranslatedText;
    const location = window.location.pathname
    return (
      <>
        {this.props.layout === 'vertical' && this.state.actions.length > 0 ?
          this.props.actions.map((eachAction) => {
            let color = eachAction.path === location ? 'white' : mainStyle.appHeader.iconColor
            return <Button style={{ width: '100%', textAlign: 'left', color: color }} onClick={() => this.doNaviation(eachAction)} variant="outlined" >
              {/* {IconLabel({ iconType: eachAction.icon, iconSize: 24, fontSize: 14, label: eachAction.label, toolTip: eachAction.tooltip })} */}
            </Button>
          })
          : null}

        {this.props.layout === 'horizontal' && this.state.actions.length > 0 ?

          this.state.actions.map((eachAction) => {
            let color = eachAction.path === location ? 'white' : mainStyle.appHeader.iconColor;
            let name = eachAction.path.replace(/\//g, '-');
            let id = `nav-btn${name}`
            return <Hidden smDown><Button id={id} key={Math.random()} 
                onClick={() => this.doNaviation(eachAction)}>
                    <Grid container spacing={2}>
                        <Grid item xs={2}>
                        {Icon({ key: eachAction.icon, size: 24, color: color })}
                        </Grid>
                        <Hidden mdDown>
                        <Grid style={{ minWidth: 75}} item xs={10}>
                            <span style={{color: color}}><Translate text={eachAction.label}></Translate></span>
                        </Grid></Hidden>
                    </Grid>
              

            </Button></Hidden>
           
          })

          : null}

      </>
    );
  }
}
