import { Reducer } from 'redux';
import { AuthUser } from 'steltix-framework-authentication-sdk';
import { UserActionTypes, UsersAction } from '.';

export interface UsersState {
	readonly items: AuthUser[];
}

// Define the initial state
export const initialUsersState: UsersState = {
	items: new Array<AuthUser>()
};

export const UsersReducer: Reducer<UsersState, UsersAction> = (
	state = initialUsersState,
	action
) => {
switch (action.type) {
	case UserActionTypes.LOAD_USERS: {
		let act = action as UsersAction
		return {
			...state,
			items: act.items
		};
	}
	
	
	default:
		return state;
}
};


