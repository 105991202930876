import { Dispatch } from 'react';
import { UserActionTypes } from './users-actions.types';
import { AppState } from '../combineReducers';
import { AuthUser } from 'steltix-framework-authentication-sdk';
import { Action } from 'redux';
import { StxAuthenticationService, StxAuthUserService } from '../../services';
import { StxAuthAppService } from '../../services/stx-auth/stx-app.service';




/*Action Types*/
export interface UsersAction extends Action {
	type: UserActionTypes;
	items: AuthUser[]
}




export const clearUsers = () => {
	return async (dispatch: Dispatch<UsersAction>) => {
		let action: UsersAction = {
			type: UserActionTypes.LOAD_USERS,
			items: new Array<AuthUser>()

		}
		dispatch(action)
	}
}



export interface GetUser {
	(appId: string): void
}


export interface EditUser {
	(user: AuthUser): void
}



export async function getUsers(dispatch: Dispatch<UsersAction>, getState: () => AppState) {
	let service = new StxAuthUserService();
	let isValid = StxAuthenticationService.isAuthenticated();
	if (isValid) {
		let items = await service.getAll();
		let action: UsersAction = {
			items: items,
			type: UserActionTypes.LOAD_USERS
		}
		dispatch(action);
	}
}

export const getUser: GetUser = (userId: string) => {
	return async (dispatch: Dispatch<UsersAction>, getState: () => AppState) => {
		let users = getState().users;
		let existing = users.items.find(x => x.userId === userId);
		if (!existing) {
			let service = new StxAuthUserService();
			let item = await service.getUser(userId);
			if (item) {
				users.items.push(item);

				let action: UsersAction = {
					type: UserActionTypes.LOAD_USERS,
					items: users.items
				};
				dispatch(action);
			}
		}
	}

}


export const addUser: EditUser = (app: AuthUser) => {
	return async (dispatch: Dispatch<UsersAction>, getState: () => AppState) => {
		let service = new StxAuthUserService();
		let newApp = await service.add(app);
		let users = getState().users;
		let index = users.items.findIndex(x => x.userId === app.userId);
		if (index >= 0) {
			users.items[index] = newApp;
		} else {
			users.items.push(newApp);
		}

		let action: UsersAction = {
			type: UserActionTypes.LOAD_USERS,
			items: users.items
		};
		dispatch(action);
	}
}

export const updateUser: EditUser = (app: AuthUser) => {
	return async (dispatch: Dispatch<UsersAction>, getState: () => AppState) => {
		let service = new StxAuthUserService();
		let newApp = await service.update(app);
		let users = getState().users;
		let index = users.items.findIndex(x => x.userId === newApp.userId);
		if (index !== -1) {
			users.items[index] = newApp;
		} else {
			users.items.push(newApp);
		}

		let action: UsersAction = {
			type: UserActionTypes.LOAD_USERS,
			items: users.items
		};
		dispatch(action);
	}
}


export type UserActions = UsersAction 