import { Breadcrumbs, Button, IconButton, Link, makeStyles } from "@material-ui/core";
import React, { Component, useState } from "react";
import { useSelector } from "react-redux";

import history from '../../../history';
import { Translate } from "react-translated";
import { AppState } from "../../../redux/combineReducers";
import { NavBarState } from "../../../redux/nav-bar";
import { IconTypes } from "./icon";

const useStyles = makeStyles((theme) => ({
    size: {
        height: 50
    }
}));


export interface BreadcrumInfo {
    path: string;
    title: string;
}

export default function BreadcrumNavigator() {

    let classnames = useStyles();

    const navBar = useSelector<AppState>(state => state.navBar) as NavBarState;

    const navigate = (path: string) => {
        history.push(path);
    }

    return (
        <Breadcrumbs classes={{ ol: classnames.size }} separator="›" aria-label="breadcrumb" >
            {
                navBar.beadcrumbs.map((item, index) => {
                   
                        return (
                            <Button key={`btn_${index}`} onClick={() => navigate(item.path)} aria-current="page">
                            <Translate text={item.title}>
                            </Translate></Button>
                        )
                   
                })
            }
        </Breadcrumbs>
    )
}