import { format } from 'date-fns'

export class DateUtils {

    private static regexIso8601 = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})([.\d]*)Z/;


    public static ReviveDateTime(key: any, value: any): any {
        var match;
        if (typeof value === "string" && (match = value.match(DateUtils.regexIso8601))) {
            var milliseconds = Date.parse(match[0]);
            if (!isNaN(milliseconds)) {
                return new Date(milliseconds);
            }
        }
        return value;
    }

    public static format(date?: Date): string {
        if (date)
            return format(date, 'Pp');
        return '';
    }


}