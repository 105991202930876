import { Action, Dispatch } from "redux";
import { MessageType, MessageActionTypes, MessagePositionVertical, MessagePostionHorizontal } from "./msg-actions.types";

export interface ShowMessageBox {
	(message: string, messageType: MessageType, duration: number | null, verticalPositon?: MessagePositionVertical, horizontalPosition?: MessagePostionHorizontal): void;
}

export interface ShowMessageAction extends Action {
    type: MessageActionTypes;
    messageType: MessageType;
    duration: number | null;
    message: string;
    verticalPosition: MessagePositionVertical,
    horizontalPosition: MessagePostionHorizontal
}


export const showMessageBox: ShowMessageBox = (message: string, messageType: MessageType, duration: number | null, 
        verticalPositon?: MessagePositionVertical, horizontalPosition?: MessagePostionHorizontal) => {
	return async (dispatch: Dispatch<ShowMessageAction>) => {


		let action: ShowMessageAction = {
			type: MessageActionTypes.SHOW_MESSAGE,
            message: message,
            duration: duration,
            messageType: messageType,
            verticalPosition: verticalPositon ? verticalPositon : MessagePositionVertical.top,
            horizontalPosition: horizontalPosition ? horizontalPosition : MessagePostionHorizontal.right
        };

		dispatch(action);
    }
}


export interface HideMessageAction extends Action {
    type: MessageActionTypes;
}



export const hideMessageBox = () => {
   let action : HideMessageAction = {
       type : MessageActionTypes.HIDE_MESSAGE
   };

   return action;
}

export type MessageAction = ShowMessageAction | HideMessageAction