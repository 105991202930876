
import { SatchelSdkError, NotFoundError, ConcurrencyError, IApiFileService, UnauthorizedError, ForbiddenError } from "satchel-common-sdk";
import httpCodes from 'http-status-codes';
import { StxAuthenticationService } from "./stx-auth/steltix-auth.service";
import { DateUtils } from "../common"
export class ApiService implements IApiFileService {

    private toBuffer(ab: ArrayBuffer): Buffer {
        var buf = Buffer.alloc(ab.byteLength);
        var view = new Uint8Array(ab);
        for (var i = 0; i < buf.length; ++i) {
            buf[i] = view[i];
        }
        return buf;
    }

    public async put(url: string, token: string, data: any): Promise<any> {
        return await this.makeCall(url, "put", token, data);
    }

    public async post(url: string, token: string, data: any): Promise<any> {
        return await this.makeCall(url, "post", token, data);
    }

    public async get(url: string, token: string): Promise<any> {
        return await this.makeCall(url, "get", token, null);
    }

    public async delete(url: string, token: string, data: any): Promise<any> {
        return await this.makeCall(url, "delete", token, data);
    }

    public static cleanUrl(url: string): string {
        let pos = url.indexOf("//");
        let prefix = url.substr(0, pos + 1);
        let rest = url.substr(pos + 1);
        rest = rest.replaceAll("//", "/");
        return prefix + rest;
    }

    public static Deserialize(data: string): any {
        return JSON.parse(data, DateUtils.ReviveDateTime);
    }



    private async getToken() {
        let token = "";

        let isValid = StxAuthenticationService.isAuthenticated();
        if (isValid) {
            let service = new StxAuthenticationService();
            token = await service.silentLogin();
        }


        return token;
    }

    private async makeCall(url: string, method: string, token: string, data: any): Promise<string | undefined> {



        let body = (data) ? JSON.stringify(data) : null;

        url = ApiService.cleanUrl(url);

        url = encodeURI(url);


        const requestOptions: RequestInit = {
            method: method,
            //  agent: new HttpProxyAgent('http://127.0.0.0:8888'),
            body: body
        };

        if (!token) {
            token = await this.getToken();
        }

        if (token && token !== "") {
            requestOptions.headers = { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" };
        } else {
            requestOptions.headers = { "Content-Type": "application/json" };
        }

        // console.log(`${method}: URL: ${url} - Json: ${requestOptions.body}`)

        try {
            let f = await fetch(url, requestOptions);
            if (f !== undefined) {
                let rep = f as Response;
                let text = await rep.text();
                switch (f.status) {

                    case httpCodes.OK: {
                        
                        // console.log(`Result: URL: ${url} - Method: ${method} - Text: ${text}`);
                        let json = ApiService.Deserialize(text);

                        return json;
                    }
                    case httpCodes.NOT_FOUND: throw new NotFoundError(text)
                    case httpCodes.INTERNAL_SERVER_ERROR: throw new SatchelSdkError(text);
                    case httpCodes.CONFLICT: throw new ConcurrencyError(text);
                    case httpCodes.UNAUTHORIZED: throw new UnauthorizedError(text);
                    case httpCodes.FORBIDDEN: throw new ForbiddenError(text);
                }

            }
            return "";
        } catch (ex) {
            console.log(`Result: URL: ${url} - Error: ${ex}`);
            throw new SatchelSdkError(ex);
        }
    }

    public async getFile(url: string, token: string): Promise<Buffer> {

        url = ApiService.cleanUrl(url);

        const requestOptions: RequestInit = {
            method: "get",
        };

        if (!token) {
            token = await this.getToken();
        }

        if (token && token !== "") {
            requestOptions.headers = { "Authorization": `Bearer ${token}`, "Content-Type": "application/json" };
        } else {
            requestOptions.headers = { "Content-Type": "application/json" };
        }

        let f = await fetch(url, requestOptions);
        if (f !== undefined) {
            switch (f.status) {
                case httpCodes.OK: {
                    let rep = f as Response;
                    let arrayBuffer = await rep.arrayBuffer();
                    let buffer = this.toBuffer(arrayBuffer)

                    return buffer;
                }
                case httpCodes.NOT_FOUND: throw new NotFoundError(f.statusText)
                case httpCodes.INTERNAL_SERVER_ERROR: throw new SatchelSdkError(f.statusText);
                case httpCodes.CONFLICT: throw new ConcurrencyError(f.statusText);
                case httpCodes.UNAUTHORIZED: throw new UnauthorizedError(f.statusText);
                case httpCodes.FORBIDDEN: throw new ForbiddenError(f.statusText);
            }
        }
        return new Buffer("utf8");
    }



    public async uploadFile(url: string, token: string, fileKey: string, file: File): Promise<any> {

        url = ApiService.cleanUrl(url);

        let formData = new FormData();
        formData.append(fileKey, file, file.name);

        const requestOptions: RequestInit = {

            method: "post",
            body: formData
        };

        if (!token) {
            token = await this.getToken();
        }


        if (token && token !== "") {
            requestOptions.headers = { "Authorization": `Bearer ${token}` };
        }

        try {
            let f = await fetch(url, requestOptions);
            if (f !== undefined) {
                switch (f.status) {
                    case httpCodes.OK: {
                        let rep = f as Response;
                        let text = await rep.text();
                        let json = ApiService.Deserialize(text);;
                        return json;
                    }
                    case httpCodes.NOT_FOUND: throw new NotFoundError(f.statusText)
                    case httpCodes.INTERNAL_SERVER_ERROR: throw new SatchelSdkError(f.statusText);
                    case httpCodes.CONFLICT: throw new ConcurrencyError(f.statusText);
                    case httpCodes.UNAUTHORIZED: throw new UnauthorizedError(f.statusText);
                    case httpCodes.FORBIDDEN: throw new ForbiddenError(f.statusText);
                }
            }
            return null;
        } catch (ex) {
            console.log(`Result: URL: ${url} - Error: ${ex}`);
            throw new SatchelSdkError(ex);
        }

    }





}