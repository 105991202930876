import { combineReducers } from 'redux';
import { CurrentUserState, cUserReducer } from './c-user/c-user-reducer'; //if you reference the folder it does not initilize correctly.
import { layoutReducer, LayoutState } from './layout';
import { navBarReducer, NavBarState } from './nav-bar';
import { messageReducer, MessageState } from './notification-msg';
import { UsersReducer, UsersState } from './users';
import { applicationsReducer, ApplicationsState } from './apps';
import { settingsReducer, SettingsState } from './settings';
import { configReducer, ConfigState } from './config';


// Create an interface for the application state
export interface AppState {
    currentUser: CurrentUserState;
    layout: LayoutState;
    message: MessageState;
    navBar: NavBarState;
    apps: ApplicationsState;
    users: UsersState;
    settings: SettingsState;
    config: ConfigState;
}

// Create the root reducer
const rootReducer = combineReducers<AppState>({
    currentUser: cUserReducer,
    message: messageReducer,
    navBar: navBarReducer,
    layout: layoutReducer,
    apps: applicationsReducer,
    users: UsersReducer,
    settings: settingsReducer,
    config: configReducer
});

export default rootReducer;