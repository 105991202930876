import { ReduxStore } from "../redux/store";
import { ApiSettings } from "../types";



export class SettingsService  {

    private _production: boolean;
    private _settings: ApiSettings;

    constructor() {
        let nodeEnv = this.getEnvValue("NODE_ENV");
        this._production = nodeEnv === 'production';
        if (this.production) {
            this._settings = {
                authApi: "/api"
            }
        } else {
            this._settings = {
                authApi: "http://localhost:8860/api"
            }
            
        }
    }

    public getEnvValue(name: string) {
        let store = ReduxStore.store.getState();

        let values = store.settings.vars;
        if (values) {
            let item = values.find(x => x.name === name);
            if (item)
                return item.value;
        }
        return "";

    }

    public get production() {
        return this._production;
    }

    public get steltixClientId() {
        if (this.production) {
            let value = this.getEnvValue("JWT_CLIENT_ID");
            return value;
        }
        return  "6ffbf238-d4cd-49e8-9879-630bd0e816ba"
        //return "ac4c0f64-04ed-449e-afae-f3f212a46d27"
    }

    public get authApi() {
       
        return this._settings.authApi;
    }

}