import { AnyAction, applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import rootReducer, { AppState } from './combineReducers';
import { StateLoader } from './state-loader';



export class ReduxStore {


    private static _instance: Store<AppState>

    private static create(includeState: boolean) {
        const composeEnhancers =
           // process.env.NODE_ENV !== "production" ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;
            (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        let store: Store<AppState>;


            store = createStore(
                rootReducer,
                StateLoader.loadState(),
                composeEnhancers(applyMiddleware(thunk))
            )
        
        store.subscribe(() => {
            //this is just a function that saves state to localStorage
            StateLoader.saveState(store.getState());
        });

        return store;

    }

    public static get store(): Store<AppState> {
        if (!ReduxStore._instance) {
            let store = ReduxStore.create(true);
            ReduxStore._instance = store;
        }

        return ReduxStore._instance;

    }

    public static dispatch(action: AnyAction): AnyAction {
        return ReduxStore._instance.dispatch(action);
    }


}

