import { AuthClient, ClientService, UserService } from "steltix-framework-authentication-sdk";
import { BaseService } from "./base.service";


export class StxAuthAppService extends BaseService {

    private _service : ClientService;

    constructor() {
        super();
        this._service = new ClientService({
            api: this.apiService,
            url: this.settingsService.authApi
        });

    }

    public async getApps() {
        return await this._service.getAll(this.token);
    }

    public async getApp(id: string) {
        return await this._service.get(id, this.token);
    }

    public async addApp(app: AuthClient) {
        return await this._service.add(app, this.token);
    }

    public async updateApp(app: AuthClient) {
        return await this._service.update(app, this.token);
    }

    

   
}