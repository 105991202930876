import { Dispatch } from 'react';
import { ApplicationActionTypes } from './apps-actions.types';
import { AppState } from '../combineReducers';
import { AuthClient, AuthUser } from 'steltix-framework-authentication-sdk';
import { Action } from 'redux';
import { StxAuthenticationService, StxAuthUserService } from '../../services';
import { StxAuthAppService } from '../../services/stx-auth/stx-app.service';




/*Action Types*/
export interface ApplicationsAction extends Action {
	type: ApplicationActionTypes;
	items: AuthClient[]
}




export const clearApplications = () => {
	return async (dispatch: Dispatch<ApplicationsAction>) => {
		let action: ApplicationsAction = {
			type: ApplicationActionTypes.LOAD_APPS,
			items: new Array<AuthClient>()

		}
		dispatch(action)
	}
}



export interface GetApplication {
	(appId: string): void
}


export interface EditApplication {
	(user: AuthClient): void
}



export async function getApplications(dispatch: Dispatch<ApplicationsAction>, getState: () => AppState) {
	let service = new StxAuthAppService();
	let isValid = StxAuthenticationService.isAuthenticated();
	if (isValid) {
		let items = await service.getApps();
		let action: ApplicationsAction = {
			items: items,
			type: ApplicationActionTypes.LOAD_APPS
		}
		dispatch(action);
	}
}

export const getApplication: GetApplication = (appId: string) => {
	return async (dispatch: Dispatch<ApplicationsAction>, getState: () => AppState) => {
		let apps = getState().apps;
		let existing = apps.items.find(x => x.clientId === appId);
		if (!existing) {
			let service = new StxAuthAppService();
			let item = await service.getApp(appId);
			if (item) {
				apps.items.push(item);

				let action: ApplicationsAction = {
					type: ApplicationActionTypes.LOAD_APPS,
					items: apps.items
				};
				dispatch(action);
			}
		}
	}

}


export const addApplication: EditApplication = (app: AuthClient) => {
	return async (dispatch: Dispatch<ApplicationsAction>, getState: () => AppState) => {
		let service = new StxAuthAppService();
		let newApp = await service.addApp(app);
		let apps = getState().apps;
		apps.items.push(newApp);

		let action: ApplicationsAction = {
			type: ApplicationActionTypes.LOAD_APPS,
			items: apps.items
		};
		dispatch(action);
	}
}

export const updateApplication: EditApplication = (app: AuthClient) => {
	return async (dispatch: Dispatch<ApplicationsAction>, getState: () => AppState) => {
		let service = new StxAuthAppService();
		let updatedItem = await service.updateApp(app);
		let apps = getState().apps;
		let index = apps.items.findIndex(x => x.clientId === updatedItem.clientId);
		if (index !== -1) {
			apps.items[index] = updatedItem;
		} else {
			apps.items.push(updatedItem);
		}


		let action: ApplicationsAction = {
			type: ApplicationActionTypes.LOAD_APPS,
			items: apps.items
		};
		dispatch(action);
	}
}


export type AppActions = ApplicationsAction 