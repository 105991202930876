import React, { Component } from "react";
import { connect, useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import history from './../../history';
import { AppState } from "../../redux/combineReducers";
import { CurrentUserState } from "../../redux";
import { StxAuthenticationService } from "../../services";
import Icon, { IconTypes } from "./helpers/icon";



export default function UserProfileComponent() {

    const currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;

    const logout = () => {
        StxAuthenticationService.logout();
        history.push('/login');
    }

    return (
        <div>
            <span id="current-user">{currentUser?.authUser?.email}</span>
            <Button onClick={logout}>{Icon({ key: IconTypes.Exit, size: 16, color: 'white' })}</Button>
        </div>
    )

}

