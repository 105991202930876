import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Menu, Theme } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux'
import UserProfileComponent from './user-profile';
import { CurrentUserState, getCurrentUser } from '../../redux';
import { AppState } from '../../redux/combineReducers';
import {mainStyle}  from '../../styles'
import BreadcrumNavigator from './helpers/breadcrum-navigator';
import { NavBarState } from '../../redux/nav-bar';
import HeaderMenu from './helpers/toolbar-actions';
import { NavActionService } from '../../services/ux';
import { Translate } from 'react-translated'


// when a theme value is needed in a component
// create the style with makeStyles
const useStyles = makeStyles((theme) => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    container: {
        flexGrow: 1,
        marginRight: theme.spacing(3),
        marginTop: 5
    }
}));

interface AppHeaderProps {
    title?: string,
    theme?: Theme
}
export default function AppHeader(props: AppHeaderProps) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const navBar = useSelector<AppState>(state => state.navBar) as NavBarState;
    const currentUser = useSelector<AppState>(state => state.currentUser) as CurrentUserState;

    // merge the local component classes with the global styles
    let _styles = { ...classes, ...mainStyle } as any;
   
    let actions = navBar.actions;
    let divDisplay = navBar.visible ? 'block' : 'none';

    useEffect(() => {
        if (!currentUser.authUser) {
            NavActionService.reset();
            dispatch(getCurrentUser)
        }
        else {
            NavActionService.init();
        }
    }, [currentUser]);
    
    return (


        <div className={_styles.appHeader.root} style={{ display: divDisplay }}>
            <AppBar style={{ ..._styles.appHeader.main }}>
                <Toolbar>
               
                    <span className={_styles.container}>
                        <span style={_styles.appHeader.title}><Translate   text="STELTIX_AUTHENTICATION"></Translate></span> 

                    
                            <span style={{ ..._styles.appHeader.vertLine }}></span>

                             <HeaderMenu layout="horizontal" actions={actions} /> 
                       
                       


                        {/* <span style={styles.appHeader.vertLine}></span> */}
                        {/* <span id='page-title' style={styles.appHeader.pageTitle}><Translate text={pageTitle} /></span> */}
                    </span>

               
                    <UserProfileComponent />
            
                </Toolbar>
                {navBar.beadcrumbs.length > 0 ? <div style={{ height: 50, width: '100%', backgroundColor: '#f7f7f7' }}><BreadcrumNavigator /></div> : <span></span>}
            </AppBar>

        </div>
    );
}