import { EnvVariable } from "../types";
import httpCodes from 'http-status-codes';



export class EnvironmentVarService {


    constructor() {

    }


    public async load() : Promise<EnvVariable[]> {

        if (process.env.NODE_ENV === "production") {
            let path = `/env`;

            //The run so early in the startup of the app it does not use the ApiService.
            const requestOptions: RequestInit = {
                method: 'get'
            }
            let f = await fetch(path, requestOptions);
            if (f.status == httpCodes.OK) {
                let rep = f as Response;
                let envVars = await rep.json();
                return envVars;
            }
        }
        return new Array<EnvVariable>();
        
    }



}