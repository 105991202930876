import { StxAuthenticationService } from "../services";
import { DataStore, DataStoreKeys } from "../services/data-store";
import { initialAppsState } from "./apps";
import { AppState } from "./combineReducers";
import { initialLayoutState } from "./layout";
import { initialNavBarState } from "./nav-bar";
import { initialMessageState } from "./notification-msg";
import { initialCUserState } from "./c-user";
import { initialUsersState } from "./users";
import { initialSettingsState } from "./settings";
import { initialConfigState } from "./config";




export class StateLoader {

    public static loadState() {
        try {
            let serializedState: AppState;
            if (StxAuthenticationService.isAuthenticated()) {
                serializedState = DataStore.getData(DataStoreKeys.REDUX);
                if (serializedState) {
                    return serializedState as AppState
                }
            }

            return StateLoader.initializeState();
        }
        catch (err) {
            return StateLoader.initializeState();
        }
    }

    public static clear() {
        DataStore.removeData(DataStoreKeys.REDUX);
    }

    public static saveState(state: AppState) {
        try {
            let serializedState = state;
            DataStore.setData(DataStoreKeys.REDUX, serializedState);
        }
        catch (err) {
        }
    }

    private static initializeState(): AppState {
        return {
            apps: initialAppsState,
            currentUser: initialCUserState,
            layout :initialLayoutState,
            message: initialMessageState,
            navBar: initialNavBarState,
            users: initialUsersState,
            settings: initialSettingsState,
            config: initialConfigState

        }
    }
}