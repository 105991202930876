import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider as TranslationProvider } from 'react-translated';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { ReduxStore } from './redux/store';
import translation from './translation.json'
import { LanguageService } from './services';

LanguageService.configure("en", translation);

ReactDOM.render(
  <Provider store={ReduxStore.store}>
    <TranslationProvider language="en" translation={translation}>
        <App />
    </TranslationProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();