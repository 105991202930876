export const mainStyle = {
    appHeader: {
        main: {
            height: 80, boxShadow: 'none', paddingTop: 5, paddingBottonm: 20, backgroundColor: 'darkblue'
        },
        title: {
            fontSize: 24,
            textTransform: 'uppercase'
        },
        vertLine: {
            borderLeft: '1px solid silver',
            height: 80,
            marginLeft: 10,
            marginRight: 10
        },
        pageTitle: {
            fontSize: 20,
            textTransform: 'uppercase'
        },
        root: {
            flexGrow: 1,
            position: 'absolute', top: 0, width: '100vw',
        },
        iconColor: '#cce6ff'
    },
    appsTable: {
        margin: '0px 0px',
        boxShadow: 'none',
        border: 'none'

    },
    usersTable: {
        margin: '0px 0px',
        boxShadow: 'none',
        border: 'none'

    },
    footer: {
        position: 'fixed', height: '70px', bottom: '0px', left: '0px', paddingLeft: '25px', paddingTop: '10px', width: '100%', paddingRight: '25px'
    },
    login: {
        labelStyle: {
            width: "calc(100% - 40px)",
            fontWeight: 'bold',
            margin: 20
        },
        textStyle: {
            width: "calc(100% - 40px)",
            margin: 20
        }
    },
    buttons: {
        backgroundColor: "#6b7c8b",
        color: "white",
        width: 150
    },
    dialogs: {
        buttons: {
            backgroundColor: "#6b7c8b",
            color: "white",
            width: 140
        },
        header: {
            textTransform: 'uppercase',
            fontWeight: 'bold'
        },
        content: {
            paddingLeft: 10,
            borderRadius: 25,
            backgroundColor: "#f5f5f5"
        }
    }
    
}