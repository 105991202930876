import { DataStore, DataStoreKeys } from ".";



interface TokenStore {
    idToken: string
}



export class TokenCache {

    public static set idToken(token: string) {
        let data: TokenStore = {
            idToken: token
        }
        DataStore.setData(DataStoreKeys.ID_TOKEN, data);
    }

    public static get idToken(): string {
        let tokenInfo = DataStore.getData(DataStoreKeys.ID_TOKEN) as TokenStore;
        if (tokenInfo) {
            return tokenInfo.idToken;
        }
        return "";
    }

   

    public static clear() : void {
        DataStore.removeData(DataStoreKeys.ID_TOKEN);

    }


}