import { Reducer } from 'redux';
import { Configuration } from 'steltix-framework-authentication-sdk';
import { ConfigActions, ConfigurationAction } from './config-actions';
import { ConfigActionTypes } from './config-actions.types';


export interface ConfigState {
	readonly items: Configuration[];
}

// Define the initial state
export const initialConfigState: ConfigState = {
	items: new Array<Configuration>()
};

export const configReducer: Reducer<ConfigState, ConfigActions> = (
	state = initialConfigState,
	action
) => {
switch (action.type) {
	case ConfigActionTypes.LOAD_CONFIGS: {
		let act = action as ConfigurationAction
		return {
			...state,
			items: act.items
		};
	}
	
	
	default:
		return state;
}
};


