import { Dispatch } from 'react';
import { ConfigActionTypes } from './config-actions.types';
import { AppState } from '../combineReducers';
import { Configuration } from 'steltix-framework-authentication-sdk';
import { Action } from 'redux';
import { StxAuthConfigService, StxAuthenticationService } from '../../services';





/*Action Types*/
export interface ConfigurationAction extends Action {
	type: ConfigActionTypes;
	items: Configuration[]
}




export const clearApplications = () => {
	return async (dispatch: Dispatch<ConfigurationAction>) => {
		let action: ConfigurationAction = {
			type: ConfigActionTypes.LOAD_CONFIGS,
			items: new Array<Configuration>()

		}
		dispatch(action)
	}
}



export interface GetConfiguration {
	(appId: string): void
}


export interface EditConfiguration {
	(item: Configuration): void
}



export async function getConfigurations(dispatch: Dispatch<ConfigurationAction>, getState: () => AppState) {
	let service = new StxAuthConfigService();
	let isValid = StxAuthenticationService.isAuthenticated();
	if (isValid) {
		let items = await service.getConfigs();
		let action: ConfigurationAction = {
			items: items,
			type: ConfigActionTypes.LOAD_CONFIGS
		}
		dispatch(action);
	}
}

export const getConfiguration: GetConfiguration = (id: string) => {
	return async (dispatch: Dispatch<ConfigurationAction>, getState: () => AppState) => {
		let configs = getState().config;
		let existing = configs.items.find(x => x._id === id);
		if (!existing) {
			let service = new StxAuthConfigService();
			let item = await service.getConfig(id);

			configs.items.push(item);


			let action: ConfigurationAction = {
				type: ConfigActionTypes.LOAD_CONFIGS,
				items: configs.items
			};
			dispatch(action);
		}
	}
}


export const updateConfig: EditConfiguration = (config: Configuration) => {
	return async (dispatch: Dispatch<ConfigurationAction>, getState: () => AppState) => {
		let service = new StxAuthConfigService();
		let updatedItem = await service.updateConfig(config);
		let configs = getState().config;

		let index = configs.items.findIndex(x => x._id === updatedItem._id);
		if (index !== -1) {
			configs.items[index] = updatedItem;
		} else {
			configs.items.push(updatedItem);
		}

		let action: ConfigurationAction = {
			type: ConfigActionTypes.LOAD_CONFIGS,
			items: configs.items
		};
		dispatch(action);
	}
}


export type ConfigActions = ConfigurationAction 