interface LanguageItem {
    [key: string]: string;
}

interface Langauges {
    [key: string]: LanguageItem
}

export class LanguageService {

    private static _langauge: string;
    private static _translations: Langauges

    public static configure(langauge: string, translations: Langauges) {
        LanguageService._langauge = langauge;
        LanguageService._translations = translations;
    }


    public static getTranslatedText(text: string | undefined ) : string {
        if (text) {
            if (LanguageService._translations.hasOwnProperty(text)) {
                let item = LanguageService._translations[text];
                if (item.hasOwnProperty(LanguageService._langauge))
                    return item[LanguageService._langauge];
            }
        }
        return text ? text : "";
    }
}