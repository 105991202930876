import { Reducer } from "redux";
import { EnvVariable } from "../../types";
import { SettingsActions, SettingsEnvVarAction, SettingsVersionAction } from "./settings.actions";
import { SettingsActionTypes } from "./settings.actions.types";

export interface SettingsState {
    readonly vars: EnvVariable[];
    readonly version: string;
}

// Define the initial state
export const initialSettingsState: SettingsState = {
    vars: new Array<EnvVariable>(),
    version: 'DEBUG'
};

export const settingsReducer: Reducer<SettingsState, SettingsActions> = (
    state = initialSettingsState,
    action
) => {
switch (action.type) {
    case SettingsActionTypes.GET_ENV_VARS: {
        let act = action as SettingsEnvVarAction;
        
        return {
            ...state,
            vars: act.vars
        };
    }
    case SettingsActionTypes.GET_VERSION: {
        let act = action as SettingsVersionAction;
        
        return {
            ...state,
            version: act.version
        };
    }
    
    default:
        return state;
}
};