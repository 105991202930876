import { Reducer } from 'redux';
import { AuthUser } from "steltix-framework-authentication-sdk";
import { CUserActions, CUserLoginAction } from "./c-user-actions";
import { CUserActionTypes } from "./c-user-actions.types";

export interface CurrentUserState {
	readonly authUser: AuthUser | null
	readonly errorMsg: string;
}

// Define the initial state
export const initialCUserState: CurrentUserState = {
	authUser: null,
	errorMsg: ''
};

export const cUserReducer: Reducer<CurrentUserState, CUserActions> = (
	state = initialCUserState,
	action
) => {
	switch (action.type) {
		case CUserActionTypes.LOGIN: {
			let act = action as CUserLoginAction

			return {
				...state,
				authUser: act.authUser,
				errorMsg: act.errorMsg
			};
		}
	
		default:
			return state;
	}
};