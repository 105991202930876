import { Action, Dispatch } from "redux"
import { AuthUser } from "steltix-framework-authentication-sdk"
import { StxAuthenticationService, StxAuthUserService } from "../../services"
import { AppState } from "../combineReducers"
import { showMessageBox } from "../notification-msg"
import { CUserActionTypes } from "./c-user-actions.types"

/*Action Types*/
export interface CUserLoginAction extends Action {
	type: CUserActionTypes;
	authUser: AuthUser | null;
	errorMsg: string;
}


export interface CUserLogoutAction extends Action {
	type: CUserActionTypes;
	authUser: AuthUser | null;
}

export const clearCurrentUser = () => {
	return async (dispatch: Dispatch<CUserLoginAction>) => {
		let action: CUserLoginAction = {
			type: CUserActionTypes.LOGIN,
			authUser: null,
			errorMsg: ''

		}
		dispatch(action)
	}
}


export interface GetSystemUser {
	(userId: string): void
}


export interface EditSystemUser {
	(user: AuthUser): void
}

export interface UserLogin {
	(userName: string, password: string): void;
}

export const userLogin: UserLogin = (userName: string, password: string) => {
	return async (dispatch: Dispatch<CUserLoginAction>) => {
		let authService = new StxAuthenticationService();
		let userService = new StxAuthUserService();
		try {
			let result = await authService.authenticate(userName, password);
			if (result.token) {
				let user = await userService.getMe();
				let action: CUserLoginAction = {
					authUser: user,
					type: CUserActionTypes.LOGIN,
					errorMsg: ''
				}
				dispatch(action)

			}
		} 
		catch (ex) {
			let action: CUserLoginAction = {
				authUser: null,
				type: CUserActionTypes.LOGIN,
				errorMsg: ex.message
			}
			dispatch(action)
		}

	}
}

export async function getCurrentUser(dispatch: Dispatch<CUserLoginAction>, getState: () => AppState) {
	let currentUser = getState().currentUser;
	let userService = new StxAuthUserService();
	let isValid = StxAuthenticationService.isAuthenticated();
	if (!currentUser.authUser && isValid) {
		let user = await userService.getMe();
		let action: CUserLoginAction = {
			authUser: user,
			type: CUserActionTypes.LOGIN,
			errorMsg: ''
		}
		dispatch(action);
	}

}


export type CUserActions = CUserLoginAction | CUserLogoutAction
