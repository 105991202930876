import { SettingsService } from '../settings.service';
import { ApiService } from '../api.service';

export class BaseService {

    private _settingsService: SettingsService;

    protected get token() {
        return "";
    }

    protected get settingsService() {
        return this._settingsService;
    }

    private _apiService: ApiService;

    protected get apiService() {
        return this._apiService;
    }

    constructor() {
        this._settingsService = new SettingsService();
        this._apiService = new ApiService();
    }

}
