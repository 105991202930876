import { Reducer } from "redux";
import { BreadcrumInfo } from "../../common/components";
import { HeaderAction } from "../../services/ux";
import { BreadcrumbAction, ConfigureNavBarAction, HideFooterAction, NavBarActions, SetNavBarPageTitleAction } from "./nav-bar-actions";
import { NavBarActionTypes } from "./nav-bar-actions.types";


export interface NavBarState {
    readonly visible : boolean
    readonly actions: HeaderAction[],
    readonly beadcrumbs: BreadcrumInfo[]
    readonly footerVisible: boolean;
}

// Define the initial state
export const initialNavBarState: NavBarState = {
    visible: false,
    footerVisible: false,
    actions: new Array<HeaderAction>(),
    beadcrumbs: new Array<BreadcrumInfo>()
};

export const navBarReducer: Reducer<NavBarState, NavBarActions> = (
    state = initialNavBarState,
    action
) => {
switch (action.type) {
    case NavBarActionTypes.CONFIGURE_NAV_BAR: {
        let act = action as ConfigureNavBarAction;
        
        return {
            ...state,
            visible : act.visible,
            actions: act.actions,
            footerVisible: act.footerVisible
        };
    }
    case NavBarActionTypes.HIDE_FOOTER : {
        let act = action as HideFooterAction;

        return {
            ...state,
            footerVisible: act.footerVisible
        }
    }
    
   
    

    case NavBarActionTypes.SET_BREAD_CRUMBS : {
        let act = action as BreadcrumbAction;

        return {
            ...state,
            beadcrumbs: act.items
        }
    }
    
    default:
        return state;
}
};