import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar, faFolderOpen, faUsers, faHistory, faClipboardList, faSignOutAlt, faPause, faCheck, faChevronRight, faLaptop, faDiceD6,
    faHome, faCog, faTable, faUser, faPlus, faHeart as faHeartSolid, faStar as faStarSolid, faMinus, faStopwatch, faSearch, faLaptopMedical, faUserPlus, faFileDownload, faFileImport, faTimes, faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt, faClock, faHeart, faStar, faComments, faStickyNote, faSquare, faHourglass, faClipboard } from '@fortawesome/free-regular-svg-icons';


export enum IconTypes {
    Admin = 'Admin',
    AppNEW = 'AppNEW',
    Apps = 'Apps',
    Busy = "Busy",
    Cancel = 'Cancel',
    Collapse = 'Collapse',
    Configuration = "Configuration",
    Check = "Check",
    Data = "Data",
    Done = "Done",
    End = 'End',
    Environment = 'Environment',
    Exit = 'Exit',
    Expand = 'Expand',
    Export = 'Export',
    History = "History",
    Import = "Import",
    Home = 'HomeIcon',
    Logs = 'Logs',
    NotDone = "NotDone",
    Search = "Search",
    Time = 'Time',
    Timeline = "Timeline",
    Timer = 'Timer',
    Upload = "Upload",

    User = 'User',
    UserNEW = 'UserApp',
    Users = "Users",
    None = 'None',
}

export interface IOptions {
    key: IconTypes;
    size: number;
    color?: string;
    spin?: boolean
}
const Icon = (options: IOptions) => {
    if (!options.color)
        options.color = 'black'
    if (!options.size)
        options.size = 12;
    let style: React.CSSProperties = { fontSize: options.size, color: options.color }

    let key = options.key;
    switch (key) {
        case IconTypes.None:
            return (
                ""
            )
        case IconTypes.Admin:
            return (
                <FontAwesomeIcon icon={faCog} style={style} />
            )
        case IconTypes.AppNEW:
            return (
                <FontAwesomeIcon icon={faLaptopMedical} style={style} />
            )

        case IconTypes.Apps:
            return (
                <FontAwesomeIcon icon={faLaptop} style={style} />
            )

            case IconTypes.Busy: {
                return (
                    <FontAwesomeIcon className={options.spin ? 'fa-spin' : ''} icon={faSpinner} style={style} />
                )
            }

        case IconTypes.Data:
            return (
                <FontAwesomeIcon icon={faTable} style={style} />
            )


        case IconTypes.Users:

            return (
                <FontAwesomeIcon icon={faUsers} style={style} />
            )

        case IconTypes.Time:
            return (
                <FontAwesomeIcon icon={faClock} style={style} />
            )
        case IconTypes.Home:
            return (
                <FontAwesomeIcon icon={faHome} style={style} />
            )

        case IconTypes.Timeline:
            return (
                // <TodayIcon style={style} />
                <FontAwesomeIcon icon={faCalendarAlt} style={style} />
            )
        case IconTypes.Timer:
            return (
                <FontAwesomeIcon icon={faStopwatch} style={style} />
            )

        case IconTypes.User:
            return (
                <FontAwesomeIcon icon={faUser} style={style} />
            )
        case IconTypes.UserNEW:
            return (
                <FontAwesomeIcon icon={faUserPlus} style={style} />
            )

        case IconTypes.History:
            return (
                <FontAwesomeIcon icon={faHistory} style={style} />
            )


        case IconTypes.Configuration:
            return (
                <FontAwesomeIcon icon={faCog} style={style} />
            )
        case IconTypes.Logs:
            return (
                <FontAwesomeIcon icon={faClipboard} style={style} />
            )




        case IconTypes.Exit: {
            return (
                <FontAwesomeIcon icon={faSignOutAlt} style={style} />
            )

        }
        case IconTypes.Expand: {
            return (
                <FontAwesomeIcon icon={faSquare} style={style} />
            )

        }
        case IconTypes.Collapse: {
            return (
                <FontAwesomeIcon icon={faMinus} style={style} />
            )

        }

        case IconTypes.End: {
            return (
                <FontAwesomeIcon icon={faCheck} style={style} />
            )
        }

        case IconTypes.Search:


        case IconTypes.Environment: {
            return (
                <FontAwesomeIcon icon={faLaptop} style={style} />
            )
        }

        case IconTypes.Export: {
            return (
                <FontAwesomeIcon icon={faFileDownload} style={style} />
            )
        }

        case IconTypes.Import: {
            return (
                <FontAwesomeIcon icon={faFileImport} style={style} />
            )
        }

        case IconTypes.Check:
            return (
                <FontAwesomeIcon icon={faCheck} style={style} />
            )

        case IconTypes.Cancel:
            return (
                <FontAwesomeIcon icon={faTimes} style={style} />
            )
    }



}

export default Icon