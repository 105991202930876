import { ConfigService, Configuration } from "steltix-framework-authentication-sdk";
import { BaseService } from "./base.service";


export class StxAuthConfigService extends BaseService {

    private _service : ConfigService;

    constructor() {
        super();
        this._service = new ConfigService({
            api: this.apiService,
            url: this.settingsService.authApi
        });

    }

    public async getConfigs() {
        return await this._service.getAll(this.token);
    }

    public async getConfig(id: string) {
        return await this._service.get(id, this.token);
    }



    public async updateConfig(item: Configuration) {
        return await this._service.update(item, this.token);
    }

    

   
}