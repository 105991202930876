import { LoginService } from 'steltix-framework-authentication-sdk'
import { decode } from 'jsonwebtoken';
import { SettingsService } from '../settings.service';
import { TokenCache } from '../data-store';
import { BaseService } from './base.service';

interface TokenResponse {
    expiresIn: number;
    user: any,
    token: string;
}

export interface DataStoredInToken {
    exp: number;
    oid: string;
    email?: string;
    preferred_username?: string
    name: string;
    iss?: string
}

export class StxAuthenticationService extends BaseService {

    private _loginService!: LoginService;
    private _settingService: SettingsService;


    constructor() {
        super();
        this._settingService = new SettingsService();
        this._loginService = new LoginService({
            url: this._settingService.authApi,
            api: this.apiService
        });

    }

    public async authenticate(userName: string, password: string) {

        let response = await this._loginService.authenticate(this._settingService.steltixClientId, userName, password);
        if (response) {
            TokenCache.idToken = response.token;
        }
        return response;
    }

    public async silentLogin() : Promise<string> {

        let token = TokenCache.idToken
        let response = await this._loginService.refreshToken(this._settingService.steltixClientId, token);
        if (response) {
            TokenCache.idToken = response.token;
            return response.token;
        }
        return "";
    }

    public static isAuthenticated(): boolean {
        let token = TokenCache.idToken;
        let valid: boolean = token !== "";
        if (valid) {
            let tokenDecoded = decode(token) as DataStoredInToken;
            let exp = tokenDecoded.exp;

            const now = Date.now().valueOf() / 1000;
            valid = exp > now;
        }
        return valid;
    }

    public static logout() {
        TokenCache.clear();
    }

}