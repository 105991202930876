import React, { Suspense, lazy } from 'react';
import { Router, Route, Switch } from "react-router-dom";
import logo from './logo.svg';
import history from './history';
import Box from '@material-ui/core/Box';
import './App.css';
import { useDispatch } from 'react-redux';
import AuthenticatedRoute from './common/security/authenticated-route';
import { Roles } from 'steltix-framework-authentication-sdk';
import AppHeader from './common/components/app-header';
import { getEnvVariables } from './redux/settings';
const LoginView = lazy(() => import('./routes/login'))
const HomeView = lazy(() => import('./routes/home'))
const RootView = lazy(() => import('./routes/root'))
const AppsView = lazy(() => import('./routes/apps'))
const AppView = lazy(() => import('./routes/apps/app'))
const UsersView = lazy(() => import('./routes/users'))
const UserView = lazy(() => import('./routes/users/user'))
const ConfigsView = lazy(() => import('./routes/config'))
const ConfigView = lazy(() => import('./routes/config/config'))

function App() {

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getEnvVariables);
  }, [])

  return (
    <>
      <AppHeader />
      <div style={{ marginTop: '130px', paddingBottom: '50px' }}>
        <Router history={history}>
          <link
            rel="stylesheet"
            href="https://fonts.googleapis.com/icon?family=Material+Icons"
          />
          <Suspense fallback={<div style={{ width: '100%', paddingTop: '5px' }}><Box display="flex" justifyContent="center">Loading...</Box></div>}>
            <Switch>
              <Route exact path="/" component={RootView} />
              <Route exact path="/login" component={LoginView} />

              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser]} path="/home" component={HomeView} />

              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/apps" component={AppsView} />
              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/apps/app" component={AppView} />
              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/apps/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})" component={AppView} />

              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/users" component={UsersView} />
              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/users/:id([0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12})" component={UserView} />

              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/config" component={ConfigsView} />
              <AuthenticatedRoute exact restrictedToRoles={[Roles.Guest, Roles.BasicUser, Roles.Admin]} path="/config/:id" component={ConfigView} />
            </Switch>
          </Suspense>
        </Router>

      </div>

    </>
  );
}

export default App;
