import { Action, Dispatch } from "redux";
import { SettingsService, StylesService } from "../../services";
import { AppState } from "../combineReducers";
import { LayoutActionTypes } from "./layout-action.types";


export interface SetPageSizeAction extends Action {
    type: LayoutActionTypes;
    gridMaxHeight : number;
}


export async function appsPageSizeChanged(dispatch: Dispatch<SetPageSizeAction>, getState: () => AppState) {

    let action : SetPageSizeAction = {
        type: LayoutActionTypes.APPS_RESIZE,
        gridMaxHeight : Math.floor(window.innerHeight - 300)
    }

    dispatch(action)

}

export async function usersPageSizeChanged(dispatch: Dispatch<SetPageSizeAction>, getState: () => AppState) {

    let action : SetPageSizeAction = {
        type: LayoutActionTypes.USERS_RESIZE,
        gridMaxHeight : Math.floor(window.innerHeight - 300)
    }

    dispatch(action)

}


export type LayoutActions = SetPageSizeAction;