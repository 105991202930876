import { Reducer } from "redux";
import { HideMessageAction, MessageAction, ShowMessageAction } from "./msg-actions";
import { MessageActionTypes, MessagePostionHorizontal, MessagePositionVertical, MessageType } from "./msg-actions.types";


export interface MessageState {
    messageType: MessageType;
    duration: number | null;
    message: string;
    verticalPosition: MessagePositionVertical,
    horizontalPosition: MessagePostionHorizontal,
    visible: boolean
}

// Define the initial state
export const initialMessageState: MessageState = {
    messageType: MessageType.Info,
    duration: 2000,
    message: '',
    visible: false,
    verticalPosition: MessagePositionVertical.top,
    horizontalPosition: MessagePostionHorizontal.right
};

export const messageReducer: Reducer<MessageState, MessageAction> = (
    state = initialMessageState,
    action
) => {
    switch (action.type) {
        case MessageActionTypes.SHOW_MESSAGE: {
            let act = action as ShowMessageAction;
            return {
                ...state,
                duration: act.duration,
                visible: true,
                message: act.message,
                messageType: act.messageType,
                verticalPosition: act.verticalPosition,
                horizontalPosition: act.horizontalPosition
            };
        }

        case MessageActionTypes.HIDE_MESSAGE: {
            return {
                ...state,
                visible: false,
                message: '',
                messageType: MessageType.Info,
                verticalPosition: MessagePositionVertical.top,
                horizontalPosition :MessagePostionHorizontal.right
            }; 
        }


        default:
            return state;
    }
};