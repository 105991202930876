
import { Roles } from "steltix-framework-authentication-sdk";
import { IconTypes } from "../../common/components";
import { configureNavBar, setBreadcrumbs, BreadcrumbAction } from "../../redux/nav-bar/nav-bar-actions";
import history from '../../history';
import { ReduxStore } from "../../redux/store";

export interface HeaderAction {
    label?: string;
    path: string;
    icon: IconTypes;
    tooltip: string;
    roles?: Roles[];
}

interface LocationActionConfiguration {
    actions: HeaderAction[];
    location: string;
    visible: boolean;
    title: string;
    footerVisible: boolean
}

export class NavActionService {

    private static _locationMatrix: LocationActionConfiguration[] = new Array<LocationActionConfiguration>();
    private static _initalized: boolean;

    public static init() {
        if (!NavActionService._initalized) {
            history.listen(location => {
                NavActionService.configureBreadcrumbs();
                NavActionService.notifyStore(location.pathname);
            });
            NavActionService.buildMatrix();
            NavActionService.notifyStore(window.location.pathname);
            NavActionService._initalized = true;
        }
    }
    static configureBreadcrumbs() {
        let dispatchFun = setBreadcrumbs();
        dispatchFun(ReduxStore.store.dispatch, null);
    }

    public static reset() {
        NavActionService._initalized  = false;
    }

    private static buildMatrix() {
        NavActionService._locationMatrix = [{
            actions: [{
                icon: IconTypes.Home,
                path: '/home',
                tooltip: "HOME",
                label: 'HOME',
                roles: [Roles.Admin]
            },
            {
                icon: IconTypes.Users,
                path: '/users',
                tooltip: "USERS",
                label: 'USERS',
                roles: [Roles.Admin]
            },
            {
                icon: IconTypes.Configuration,
                path: '/config',
                tooltip: "CONFIGURATION",
                label: 'CONFIGURATION',
                roles: [Roles.Admin]
            },
            {
                icon: IconTypes.Apps,
                path: '/apps',
                tooltip: "APPS",
                label: 'APPS',
                roles: [Roles.Admin]
            },
            {
                icon: IconTypes.AppNEW,
                path: '/apps/app',
                tooltip: "NEW_APP",
                label: 'NEW_APP',
                roles: [Roles.Admin]
            }],
            location: 'home',
            visible: true,
            title: 'HOME',
            footerVisible: true
        }, {
            actions: [],
            location: 'login',
            visible: false,
            title: 'LOGIN',
            footerVisible: false
        }]
        
    }

    public static handleRoute(action: HeaderAction) {

        history.push(action.path);
       
    }

    public static get items() {
        return NavActionService._locationMatrix;
    }

    public static get dashboardItems() {
        return NavActionService._locationMatrix.find(x => x.location === 'educator');
    }

    private static hasRole(userRoles: Roles[], requiredRoles?: Roles[]) {
        if (requiredRoles) {
            for (let i = 0; i < userRoles.length; i++) {
                const role = userRoles[i];
                if (requiredRoles.includes(role)) {
                    return true;
                }
            }
        }
        return false;
    }



    private static notifyStore(location: string) {
        let users = ReduxStore.store.getState().currentUser.authUser;


        let parts = location.split("/");
        parts = parts.filter(x => (x));
        let loc: string | undefined;
        loc = parts.shift();

        if (!loc) {
            loc = "/"
        }

        let actions: HeaderAction[] = new Array<HeaderAction>();

        if (users) {
            let roles = users.roles as Roles[];

            let config = NavActionService._locationMatrix.find(x => x.location === loc);
            if (config) {
                let configActions = config.actions.filter(x => this.hasRole(roles, x.roles));
                actions.push(...configActions);
                let action = configureNavBar(config.visible, actions, config.footerVisible);
                ReduxStore.dispatch(action);
            }
        }
    }


}

