import { Reducer } from 'redux';
import { AuthClient } from 'steltix-framework-authentication-sdk';
import { AppActions, ApplicationActionTypes, ApplicationsAction } from '.';

export interface ApplicationsState {
	readonly items: AuthClient[];
}

// Define the initial state
export const initialAppsState: ApplicationsState = {
	items: new Array<AuthClient>()
};

export const applicationsReducer: Reducer<ApplicationsState, AppActions> = (
	state = initialAppsState,
	action
) => {
switch (action.type) {
	case ApplicationActionTypes.LOAD_APPS: {
		let act = action as ApplicationsAction
		return {
			...state,
			items: act.items
		};
	}
	
	
	default:
		return state;
}
};


