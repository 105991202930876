import { AuthUser, UserService } from "steltix-framework-authentication-sdk";
import { BaseService } from "./base.service";


export class StxAuthUserService extends BaseService {

    private _userService : UserService;

    constructor() {
        super();
        this._userService = new UserService({
            api: this.apiService,
            url: this.settingsService.authApi
        });

    }

    public async getMe() {
        return await this._userService.getMe(this.token, this.settingsService.steltixClientId);
    }

    public async getUser(userId: string) {
        return await this._userService.get(userId, this.token);
    }

    public async getAll() {
        return await this._userService.getAll(this.token);
    }

    public async add(user: AuthUser) {
        return await this._userService.add(user, this.token)
    }

    public async update(user: AuthUser) {
        return await this._userService.update(user, this.token)
    }

    public async validatePassword(password: string) {
        return await this._userService.validatePassword(password, this.token);
    }

   
}